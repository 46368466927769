<template>
    <div class="e-form-tooltip" :class="['bg-' + variant, right ? 'right' : 'left', {'no-triangle': noTriangle}]">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: `e-form-tooltip`,
    props: {
        noTriangle: {type: Boolean},
        right: {type: Boolean},
        variant: {type: String, default: ``}
    }
}
</script>

<style lang="scss" scoped>
.e-form-tooltip {
    border: 2px solid var(--input-border-color);
    border-radius: var(--input-border-radius);
    padding: 5px 10px;
    position: relative;
    margin-top: 15px;

    &:after,
    &:before {
         content: "";
         position: absolute;
         top: -18px;
         left: 10px;
         border: 8px solid transparent;
         border-bottom-color: var(--input-border-color);
     }

    &.right {
        &:after,
        &:before {
            left: auto;
            right: 10px;
        }
    }

    &.bg-success,
    &.bg-danger,
    &.bg-warning {
         color: white;
     }
    &.bg-success:before {
         border-bottom-color: var(--success);
     }
    &.bg-warning:before {
         border-bottom-color: var(--warning);
     }
    &.bg-danger:before {
         border-bottom-color: var(--danger);
     }

    &.no-triangle {
        &:after,
        &:before {
            content: initial;
            display: none;
        }
    }
}
</style>
