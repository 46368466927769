import Vue from "vue";

Vue.mixin({
    computed: {
        currentApplication() {
            return this.$store.state.application;
        }
    }
});

export default {};
