<template>
    <app-page fullscreen class="d-flex justify-content-center align-items-center" :data-cy="getLanguage()">
        <login-page v-if="currentApplication" show-slideshow>
            <div class="login-header">
                <small v-tr>Welcome to|Bienvenue sur</small>
                <div>{{ currentApplication.name }}</div>
            </div>

            <div v-if="logoUrl">
                <b-img :src="logoUrl" style="margin-top: 20px; max-height: 100px;"/>
            </div>

            <template v-if="loading">
                <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
            </template>
            <template v-else>
                <div class="login-header-subsection">
                    <span v-tr>Sign In|Se connecter</span> <a @click="changeLanguage()" class="link-basic float-right" data-cy="language-button" v-tr>FR|EN</a>
                </div>

                <form class="login">
                    <div class="form-group" v-if="passwordEnabled">
                        <label for="login-username" v-tr>Your Username / Email|Nom d'utilisateur / Courriel</label>
                        <input id="login-username" class="form-control" name="username" required v-model="username" type="text" placeholder=""/>
                    </div>

                    <template v-if="passwordEnabled">
                        <LoginPassword :username="username" @user="postLoginProcess"></LoginPassword>
                    </template>

                    <div class="text-right">
                        <div class="other-sign-in text-center mt-3">
                            <div class="login-divider" v-show="passwordEnabled && (googleEnabled || microsoftEnabled)" v-tr>OR|OU</div>
                            <LoginGoogle v-if="googleEnabled" :small="microsoftEnabled" @user="postLoginProcess"></LoginGoogle>
                            <LoginMicrosoft :small="googleEnabled" @user="postLoginProcess"></LoginMicrosoft>
                        </div>
                    </div>
                </form>
            </template>

            <div class="text-center mt-3">
                <p class="contact-us mt-3 mb-1" v-tr>Contact us|Contactez-nous</p>
                <!--                    <a class="login-link link-basic f-10" href="tel:+15146915115">514.395.9327</a><br>-->
                <a class="login-link link-basic f-10" style="text-decoration: underline" href="mailto:support@eyeinmedia.com">support@eyeinmedia.com</a>

                <div class="login-footer mb-1">Eye-In Média</div>

                <template v-if="currentApplication.terms.required">
                    <a class="login-link link-basic f-10" @click="termsAndConditionsVisibles = true" style="text-decoration: underline" v-tr>
                        Terms and Conditions of Usage|Termes et conditions d'utilisation
                    </a>
                </template>
            </div>
        </login-page>

        <b-modal v-model="termsAndConditionsVisibles" size="lg" scrollable no-close-on-backdrop
                 :title="tr(`Terms and Conditions of Use|Termes et conditions d'utilisation`)" hide-footer>
            <e-terms-and-policies v-if="termsAndConditionsVisibles" :source="currentApplication.terms.source || currentApplication.app" no-shortcuts/>
        </b-modal>
    </app-page>
</template>

<script>
import LoginPage from "@/layout/login-page.vue";
import {swal} from "vue-swal";
import ETermsAndPolicies from "../../vue-components/components/e-terms-and-policies.vue";
import Network from "../../vue-components/helpers/Network";
import {changeLanguage, getLanguage} from "../../vue-components/helpers/i18n";
import moment from "moment-mini";
import LoginMicrosoft from "@/components/LoginMicrosoft.vue";
import LoginPassword from "@/components/LoginPassword.vue";
import LoginGoogle from "@/components/LoginGoogle.vue";

export default {
    name: `login`,
    components: {LoginPage, ETermsAndPolicies, LoginGoogle, LoginPassword, LoginMicrosoft},
    data() {
        return {
            loading: true,
            app: `eye-intelligence`,
            appName: `Eye-Intelligence`,
            username: ``,
            logoUrl: null,
            passwordEnabled: true,
            googleEnabled: true,
            microsoftEnabled: true,
            eyeinEmployeeOnly: false,
            redirectURL: null,
            termsAndConditionsVisibles: false,
            locale: null
        }
    },
    computed: {
        pageStyle() {
            return {
                "--bg-image": `url(${this.theme.background.src})`
            };
        },
        backgroundImage() {
            return `url(${this.theme.background.src})`;
        },
        theme() {
            if (this.currentApplication && this.currentApplication.theme) {
                return this.currentApplication.theme;
            } else {
                let theme = {
                    background: {
                        src: `https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/login_bg.jpg`
                    },
                    slideshow: {
                        duration: 4000,
                        slides: [
                            {
                                image: {
                                    en: `https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/5_do_you_know_eyeintelligence_en.jpg`,
                                    fr: `https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/5_do_you_know_eyeintelligence_fr.jpg`,
                                }
                            },
                            {
                                image: {
                                    en: `https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/2_survey_en.jpg`,
                                    fr: `https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/2_survey_fr.jpg`,
                                }
                            },
                            {
                                image: {
                                    en: `https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/3_newsletter_en.jpg`,
                                    fr: `https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/3_newsletter_fr.jpg`,
                                }
                            },
                            {
                                image: {
                                    en: `https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/4_data_single_application_en.jpg`,
                                    fr: `https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/4_data_single_application_fr.jpg`,
                                }
                            },
                            {
                                image: {
                                    en: `https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/6_contact_en.jpg`,
                                    fr: `https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/6_contact_fr.jpg`,
                                }
                            },
                            {
                                image: {
                                    en: `https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/login_azure.jpg`,
                                    fr: `https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/login_azure.jpg`,
                                }
                            }
                        ]
                    }
                };
                return theme;
            }
        }
    },
    async activated() {
        const searchParams = new URLSearchParams(window.location.search);

        this.app = this.currentApplication.app;
        this.logoUrl = this.currentApplication.currentDomain.logoUrl;
        this.googleEnabled = this.currentApplication.currentDomain.authMethods.google;
        this.microsoftEnabled = this.currentApplication.currentDomain.authMethods.microsoft;
        this.passwordEnabled = this.currentApplication.currentDomain.authMethods.password;
        this.eyeinEmployeeOnly = this.currentApplication.eyeinEmployeeOnly;

        sessionStorage.setItem(`redirect_url`, this.$store.state.redirectUrl);
        localStorage.setItem(`redirect_url`, this.$store.state.redirectUrl);
        this.redirectURL = new URL(this.$store.state.redirectUrl);

        this.$forceUpdate();

        if (searchParams.get(`logout`)) {
            localStorage.removeItem(`token`);
            await Network.removeToken();
            this.loading = false;
            return;
        }

        const token = localStorage.getItem(`token`);
        if (!token) {
            this.loading = false;
            return;
        }

        try {
            await Network.setToken(token);

            const resp = await Network.get(`/users/current-user`);

            const user = resp.data;
            if (!this.eyeinEmployeeOnly || user.user_level === `admin`) {
                this.postLoginProcess(user);
            } else {
                localStorage.removeItem(`token`);
                await Network.removeToken();
                this.loading = false;
            }
        } catch {
            localStorage.removeItem(`token`);
            await Network.removeToken();
            this.loading = false;
        }
    },
    methods: {
        changeLanguage() {
            changeLanguage();
            this.$forceUpdate();
            this.locale = getLanguage();
            window.location.reload();
        },
        async postLoginProcess(user) {
            try {
                if (this.$store.state.testMode) {
                    swal({
                        icon: `success`,
                        title: `Success`,
                        text: `Login has been validated`
                    });
                    this.loading = false;
                } else {
                    await Network.setToken(user.token);
                    localStorage.setItem(`token`, user.token);
                    const tokenExpiration = moment.unix(JSON.parse(atob(user.token.split(`.`)[1])).exp);
                    localStorage.setItem(`token_expiration`, tokenExpiration.toISOString(true));

                    this.redirectURL.searchParams.set(`language`, getLanguage());
                    this.redirectURL.searchParams.set(`token`, user.token);
                    window.location.href = this.redirectURL.toString();
                }
            } catch (e) {
                console.error(e);
            }
        },
        isLocalDomain(domain) {
            return /^localhost(:\d{1,5})?$/.test(domain) || /^192\.168\.\d{1,3}\.\d{1,3}(:\d{1,5})?$/.test(domain) || /^10\.\d{1,3}\.\d{1,3}\.\d{1,3}(:\d{1,5})?$/.test(domain)
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
