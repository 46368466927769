import Vue from 'vue';
import Vuex from 'vuex';
import Network from "../vue-components/helpers/Network.js";
import router from "@/router.js";

Vue.use(Vuex);

function isLocalDomain(domain) {
    return /^localhost(:\d{0,5})?$/.test(domain) || /^192\.168\.\d{1,3}\.\d{1,3}(:\d{0,5})?$/.test(domain) || /^10\.\d{1,3}\.\d{1,3}\.\d{1,3}(:\d{0,5})?$/.test(domain);
}

export default new Vuex.Store({
    state: {
        testMode: false,
        user: null,
        application: null,
        terms: {
            accepted: false,
            id: null,
            version: null
        },
        tmpUser: null,// for terms and conditions
        termsAndPolicies: [],
        redirectUrl: null,
        sourceDomain: null
    },
    getters: {
        isAuthenticated(state) {
            return !!state.user;
        },
        isApplicationLoaded(state) {
            return !!state.application;
        }
    },
    mutations: {
        authSuccess(state, user) {
            state.user = user;
        },
    },
    actions: {
        loadApplication({state}) {
            return new Promise((resolve, reject) => {
                if (state.application) {
                    return resolve(state.application);
                }

                const searchParams = new URLSearchParams(window.location.search);

                let redirectUrl = searchParams.get(`redirect_url`);
                let domain;

                state.testMode = searchParams.get(`test`) != null;
                if (state.testMode) {
                    sessionStorage.removeItem(`redirect_url`);
                    localStorage.removeItem(`redirect_url`);
                    localStorage.removeItem(`token`);
                }

                if (!redirectUrl) {
                    redirectUrl = sessionStorage.getItem(`redirect_url`);
                }
                if (!redirectUrl) {
                    redirectUrl = localStorage.getItem(`redirect_url`);
                }

                if (!domain && redirectUrl) {
                    domain = new URL(redirectUrl).host;
                }

                if (isLocalDomain(domain)) {
                    domain = `localhost.com`;
                } else if (window.env.VUE_APP_ENV === `local` && !redirectUrl) {
                    domain = `localhost.com`;
                    redirectUrl = `https://dev.eyeintelligence.com`;
                    state.testMode = true;
                } else {
                    if (!redirectUrl) {
                        redirectUrl = window.env.VUE_APP_URLS__EYEINTELLIGENCE;
                        domain = new URL(redirectUrl).host;
                    }
                }

                state.sourceDomain = domain;
                state.redirectUrl = redirectUrl;

                Network.get(`/applications/app-by-domain/${domain}`)
                    .then(resp => {
                        state.application = resp.data;
                        resolve(resp.data, redirectUrl, domain);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        getCurrentUser({commit}) {
            return new Promise((resolve, reject) => {
                let token = localStorage.getItem(`token`);

                if (!token) {
                    const currentURL = new URL(document.URL);
                    const currentURLParams = new URLSearchParams(currentURL.search);

                    if (currentURLParams.has(`token`)) {
                        token = currentURLParams.get(`token`);
                    }
                    window.history.pushState({}, document.title, `/`);
                }

                if (token) {
                    Network.setToken(token);

                    Network.get(`/users/current-user`)
                        .then(response => {
                            const user = response.data;
                            if (user) {
                                commit(`authSuccess`, user);
                                resolve(user);
                            }
                        })
                        .catch(err => {
                            /*
                            To avoid being disconnected when server restart when working locally
                             */
                            if (window.env.VUE_APP_ENV !== `local`) {
                                localStorage.removeItem(`token`);
                                Network.removeToken();
                            }
                            reject(err);
                        });
                } else {
                    resolve(false);
                }
            });
        },
        logout() {
            localStorage.removeItem(`token`);
            Network.removeToken();
            router.push(`/`)
        },
        async setTermsAndPolicies({state}, {termsAndPolicies, user}) {
            state.termsAndPolicies = termsAndPolicies;
            state.tmpUser = user;
            await Network.setToken(user.token);
        },
        acceptTerms({state}) {
            return new Promise((resolve, reject) => {
                Network.post(`/users/current-user/accept-terms`, {
                    app: state.application.app,
                    terms: state.terms
                })
                    .then(() => {
                        resolve(state.tmpUser);
                    })
                    .catch(err => {
                        reject(err);
                    })
            });
        }
    },
    modules: {}
});
