<template>
    <div class="login-page" :class="showSlideshow ? 'slideshow-visible' : 'compact-mode'">
        <section class="login-block">
            <div class="login-left">
                <slot/>
            </div>
            <div class="login-right" v-if="showSlideshow">
                <b-carousel :interval="4000" controls indicators>
                    <b-carousel-slide v-for="(slide, index) in theme.slideshow.slides" :key="`slide-${index}`" :img-src="tr(slide.image)"/>
                </b-carousel>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: `login-page`,
    props: {
        showSlideshow: {type: Boolean}
    },
    computed: {
        pageStyle() {
            return {
                "--bg-image": `url(${this.theme.background.src})`
            };
        },
        backgroundImage() {
            return `url(${this.theme.background.src})`;
        },
        theme() {
            if (this.currentApplication && this.currentApplication.theme) {
                return this.currentApplication.theme;
            } else {
                let theme = {
                    background: {
                        src: `https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/login_bg.jpg`
                    },
                    slideshow: {
                        duration: 4000,
                        slides: [
                            {
                                image: {
                                    en: `https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/5_do_you_know_eyeintelligence_en.jpg`,
                                    fr: `https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/5_do_you_know_eyeintelligence_fr.jpg`,
                                }
                            },
                            {
                                image: {
                                    en: `https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/2_survey_en.jpg`,
                                    fr: `https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/2_survey_fr.jpg`,
                                }
                            },
                            {
                                image: {
                                    en: `https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/3_newsletter_en.jpg`,
                                    fr: `https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/3_newsletter_fr.jpg`,
                                }
                            },
                            {
                                image: {
                                    en: `https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/4_data_single_application_en.jpg`,
                                    fr: `https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/4_data_single_application_fr.jpg`,
                                }
                            },
                            {
                                image: {
                                    en: `https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/6_contact_en.jpg`,
                                    fr: `https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/6_contact_fr.jpg`,
                                }
                            },
                            {
                                image: {
                                    en: `https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/login_azure.jpg`,
                                    fr: `https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/login_azure.jpg`,
                                }
                            }
                        ]
                    }
                };
                return theme;
            }
        }
    }
}
</script>

<style lang="scss">
.login-page {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    background: url("https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/login_bg.jpg") no-repeat center;
    background-image: v-bind(backgroundImage);
    background-size: cover;
    min-height: 100%;
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;

    .login-block {
        position: relative;
        display: inline-block;
        padding: 0;
        width: 1280px;
        height: 800px;
        max-width: calc(100% - 20px);
        box-sizing: border-box;
        margin-top: 0;
        vertical-align: top;
        border-radius: 10px;
        font-size: 12pt;
        min-height: 550px;
        color: white;
        background: linear-gradient(8deg, #2b2838 0%, #415368 100%);
        overflow: hidden;
        box-shadow: 0 12px 25px rgba(black, 0.15);

        .login-left {
            position: absolute;
            display: inline-block;
            padding: 30px 40px;
            top: 0;
            left: 0;
            width: 380px;
            height: 100%;
            vertical-align: top;

            @media(min-width: 1100px) {
                max-height: calc(100vh - 80px);
                overflow: hidden auto;
            }

            .login-header {
                font-size: 28px;
                font-weight: lighter;
                color: white;
                font-variant: petite-caps;
                letter-spacing: 2px;
            }

            .login-header-subsection {
                font-weight: 100;
                margin: 20px 0;
                text-align: left;
                letter-spacing: 2px;

                .link-basic {
                    opacity: 0.7;
                    transition: 150ms;
                    color: inherit;
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline !important;
                    }
                }
            }

            label {
                color: #56b9d7;
                border-radius: 5px;
                font-weight: 400;
                font-size: 10pt;
                line-height: 20px;
                text-align: left;
                letter-spacing: 2px;
                margin-bottom: 2px;
                margin-left: 10px;
                width: 100%;
            }

            input {
                padding: 5px 10px;
                font-weight: 500;
                font-size: 11pt;
                border: none;
                border-bottom: 2px solid #56b9d7;
                color: white;
                width: 100%;
                background-color: transparent !important;
                border-radius: 0;
                margin-bottom: 20px;
            }

            .contact-us {
                font-size: 10pt;
                letter-spacing: 1px;
                text-transform: uppercase;
                opacity: 0.8;
            }

            .login-divider {
                margin: 10px;
                font-size: 9pt;
                letter-spacing: 1px;
                opacity: 0.7;
            }

            .login-footer {
                margin-top: 20px;
                font-size: 9pt;
                letter-spacing: 2px;
            }
        }

        .login-right {
            position: absolute;
            width: calc(100% - 380px);
            display: inline-block;
            height: 100%;
            top: 0;
            right: 0;
            border-top-right-radius: inherit;
            border-bottom-right-radius: inherit;
        }
    }

    .carousel {
        height: 100%;

        &::v-deep .carousel-inner {
            height: 100%;

            .carousel-item {
                height: 100%;

                img {
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        &::v-deep .carousel-indicators li {
            border-radius: 50%;
            width: 6px;
            height: 6px;
            margin-right: 6px;
            margin-left: 6px;
        }
    }

    &.compact-mode {
        .login-block {
            width: 380px;
            height: 800px;
        }
    }
}

@media(max-height: 800px) {
    .login-page {
        padding-top: 0;
    }
}

@media(max-width: 1024px) {
    .login-page {
        padding: 0;

        .login-block {
            max-width: 100%;

            .login-left {
                padding: 15px;
                width: 100%;
            }

            .login-right {
                display: none;
            }
        }
    }
}
</style>
