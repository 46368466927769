<template>
    <div class="e-terms-and-policies">
        <template v-if="termsAndPolicies && termsAndPolicies.length > 0">
            <div v-if="termsAndPolicies.length > 1 && !noShortcuts" class="terms-index">
                <b-btn variant="light" @click="scrollTo(policy.type)" pill class="mr-2" v-for="policy of termsAndPolicies" :key="'index-' + policy.id">
                    {{policy.name|tr}}
                </b-btn>
            </div>

            <section class="terms-section" v-for="policy in termsAndPolicies" :key="policy.id">
                <div class="terms-anchor" :id="policy.type"></div>
                <div v-if="termsAndPolicies.length > 1" class="terms-title">
                    <h1>{{policy.name|tr}}</h1>
                </div>
                <div v-if="tr(policy.header)" class="terms-header" v-html="tr(policy.header)"></div>
                <div class="terms-body" v-html="tr(policy.html_content)"></div>
            </section>
        </template>
        <e-empty-placeholder v-else-if="!loading" description="This Link is not valid|Ce lien n'est pas valide"/>
    </div>
</template>

<script>
import Network from "../helpers/Network";
import EEmptyPlaceholder from "./e-empty-placeholder.vue";

export default {
    name: `e-terms-and-policies`,
    components: {EEmptyPlaceholder},
    props: {
        source: {type: String, required: true},
        terms: {type: Array},
        noShortcuts: {type: Boolean},
        type: {type: String, default: `all`} // all, privacy-policy, terms-of-use, shipping-policy, return-policy
    },
    data() {
        return {
            loading: false,
            title: ``,
            termsAndPolicies: null
        }
    },
    mounted() {
        if (this.terms) {
            this.setTermsAndPolicies(this.terms);
        } else {
            this.getTermsAndConditions();
        }
    },
    methods: {
        getTermsAndConditions() {
            let type = this.type || `all`;
            this.loading = true;
            Network.get(`/terms-and-policies/${this.source}/${type}`, {}, {system_api: true})
                .then(resp => {
                    this.setTermsAndPolicies(resp.data);
                    this.loading = false;
                })
        },
        setTermsAndPolicies(terms) {
            this.termsAndPolicies = [];
            for (let policy of terms) {
                policy.name = JSON.parse(policy.name);
                policy.header = JSON.parse(policy.header);
                policy.html_content = JSON.parse(policy.html_content);
                this.termsAndPolicies.push(policy);
            }
            if (this.termsAndPolicies.length === 1) {
                this.$emit(`update:title`, this.termsAndPolicies[0].name);
            } else {
                this.$emit(`update:title`, { en: `Terms and Policies`, fr: `Termes et politiques` });
            }
        },
        scrollTo(id) {
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({ behavior: `smooth` });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.e-terms-and-policies {
    .terms-section {
        background: #fff;
        border-radius: 8px;
        color: #333;
        box-shadow: 0 15px 25px rgba(black, 0.15);
        overflow: hidden;

        & + .terms-section {
            margin-top: 20px;
        }
    }

    .terms-title {
        text-align: center;
        border-bottom: 1px solid #eee;
        padding: 15px;
        background: #fafafa;
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;

        h1 {
            font-size: 15pt;
            text-align: center;
            margin: 0;
        }
    }

    .terms-header {
        background: linear-gradient(to bottom, rgba(134, 177, 204, 1) 0%, rgba(98, 146, 175, 1) 100%);
        color: white;
        padding: 15px 15px 10px;

        p {
            margin-bottom: 15px;
        }

        &:first-child {
            border-top-left-radius: inherit;
            border-top-right-radius: inherit;
        }
    }

    .terms-anchor {
        position: relative;
        top: -120px;
    }

    .terms-body {
        padding: 15px;
        font-size: 10pt;
        text-align: left;

        ::v-deep {

            h1 {
                margin-top: 25px;
                margin-bottom: 10px;

                font-size: 18pt;
                color: #599ec2;
                font-weight: 500;

                &:first-child {
                    margin-top: 0;
                }
            }

            h2 {
                margin-top: 25px;
                margin-bottom: 10px;

                font-size: 15pt;
                color: #599ec2;
                font-weight: 500;

                &:first-child {
                    margin-top: 0;
                }
            }

            h3 {
                margin-top: 20px;
                margin-bottom: 10px;
                padding-left: 15px;

                font-size: 15pt;
                color: #333;
                font-weight: 400;
            }

            .blockquote {
                border-left: 5px solid #75BDE2;
                padding: 5px 15px;
                display: block;
                color: #111;
                margin-left: 5px;
                font-weight: 600;
            }

            a {
                text-decoration: underline;
                font-weight: 500;
                color: #12A9D8 !important;
            }

            ol, ul {
                padding-left: 25px;

                li {
                    margin-bottom: 5px;
                }
            }

            .secondary-list {
                padding-top: 10px;

                > li {
                    list-style-type: lower-alpha;
                }
            }

            .big-letter-list {
                > li {
                    list-style-type: upper-alpha;
                }
            }

            ol,
            ul,
            p {
                margin-bottom: 10px;
            }
        }
    }
}
</style>
