<template>
    <b-modal v-model="visibleComputed" @hidden="cancel" modal-class="modal-terms-and-policies" size="lg" scrollable no-close-on-backdrop
             :title="tr(`Terms and Conditions of Use|Termes et conditions d'utilisation`)">
        <div ref="terms" @scroll="onScroll" class="terms-and-policies-container">
            <e-terms-and-policies v-if="visibleComputed" :source="currentApplication.app" :terms="$store.state.termsAndPolicies" no-shortcuts/>
        </div>

        <div class="scroll-hint" v-if="!readEntirely">
            <div class="bubble">
                <div class="f-10 f-500" v-tr>
                    Please scroll down to read the entire document to continue|
                    Merci de défiler vers le bas pour lire le document en entier et continuer.
                </div>
                <i class="fas fa-arrow-down ml-2"/>
            </div>
        </div>

        <template #modal-footer>
            <div class="w-100">
                <b-form-group>
                    <b-checkbox :disabled="!readEntirely" v-model="accepted">
                        <span class="f-500" v-tr>
                            I confirm that I have read and agree to the Terms and Conditions and the Privacy Policy above.|
                            Je confirme que j'ai lu et accepté les termes et conditions ainsi que la politique de confidentialité.
                        </span>
                    </b-checkbox>
                </b-form-group>

                <div class="text-right mt-2">
                    <b-btn class="f-500" @click="cancel">
                        <span v-tr>Cancel|Annuler</span>
                    </b-btn>
                    <b-btn class="f-500 ml-2" variant="blue" :disabled="!readEntirely || !accepted" @click="accept">
                        <span v-tr>Agree|J'accepte</span>
                    </b-btn>
                </div>
            </div>
        </template>
    </b-modal>
</template>

<script>
import ETermsAndPolicies from "../../vue-components/components/e-terms-and-policies.vue";

export default {
    name: `modal-terms-and-policies`,
    components: {ETermsAndPolicies},
    props: {
        visible: {type: Boolean}
    },
    computed: {
        visibleComputed: {
            get() {
                return this.visible;
            },
            set(value) {
                this.$emit(`update:visible`, value);
            }
        },
        termsOfUse() {
            return this.$store.state.termsAndPolicies.find(t => t.type === `terms-of-use`);
        }
    },
    data() {
        return {
            readEntirely: false,
            accepted: false,
            eventListener: false
        }
    },
    methods: {
        accept() {
            this.$store.state.terms.accepted = true;
            if (this.termsOfUse) {
                this.$store.state.terms.version = this.termsOfUse.version;
                this.$store.state.terms.id = this.termsOfUse.id || this.termsOfUse._id;
            }
            this.$emit(`accepted`, true);
            this.visibleComputed = false;
        },
        cancel() {
            this.visibleComputed = false;
        },
        onScroll() {
            const terms = this.$refs.terms;
            const readEntirely = terms.scrollTop > terms.scrollHeight - terms.clientHeight - 100;
            // debug remove this after
            // this.readEntirely = readEntirely;
            if (readEntirely) {
                this.readEntirely = true;
            }
        }
    }
}


</script>

<style lang="scss">
.modal-terms-and-policies {
    .modal-body {
        padding: 0;
        overflow: hidden;
    }

    .terms-and-policies-container {
        position: relative;
        height: 100%;
        overflow: auto;
        padding: 0 5px;
        border-block: 5px solid #183848;
    }

    .scroll-hint {
        position: absolute;
        bottom: 10px;
        width: 100%;
        left: 0;
        display: flex;

        .bubble {
            display: flex;
            padding: 5px 10px;
            margin: 0 auto;
            width: auto;
            border-radius: 20px;
            background: rgba(black, 0.8);
            backdrop-filter: blur(5px);
            color: white;
            box-shadow: 0 5px 25px rgba(black, 0.25);
        }
    }
}

</style>
