<template>
    <app-page fullscreen class="d-flex justify-content-center align-items-center" :data-cy="getLanguage">
        <login-page>
            <div class="login-header">
                <div>{{ currentApplication.name }}</div>
            </div>

            <div class="login-header-subsection">
                <span v-tr>Reset my password|Réinitialiser mot de passe</span> <a @click="changeLanguage()" class="link-basic float-right" v-tr>FR|EN</a>
            </div>

            <form class="login" @submit.prevent="changePassword">
                <b-form-group>
                    <label for="login-password">
                        <span v-tr>New password|Nouveau mot de passe</span>
                    </label>
                    <e-input id="login-password" class="form-control" name="password" required v-model="password" type="password" :state="passwordValid" placeholder=""/>
                    <e-form-tooltip v-if="!passwordValid && password" variant="danger">
                        <span v-tr>Password is not strong enough|Le mot de passe n'est pas assez fort.</span>
                    </e-form-tooltip>
                    <div class="form-help text-left">
                        <span v-tr>Password need to have at least 8 characters, at least 1 uppercase letter, and at least 1 number|Le mot de passe doit avoir au moins 8 caractères, dont une majuscule et au moins un chiffre</span>
                    </div>
                </b-form-group>

                <b-form-group>
                    <label for="login-repeat-password" v-tr>Confirm|Confirmation</label>
                    <e-input id="login-repeat-password" class="form-control" name="password" required v-model="passwordConfirmation" :state="confirmationValid" type="password" placeholder=""/>
                    <e-form-tooltip v-if="!confirmationValid && passwordConfirmation" variant="danger">
                        <span v-tr>Confirmation doesn't match password|La confirmation ne correspond pas au mot de passe</span>
                    </e-form-tooltip>
                </b-form-group>
                <div class="text-right">
                    <b-btn type="submit" variant="primary" pill block data-cy="submit-btn-password-update" v-tr>Update my password|Changer mon mot de passe</b-btn>
                </div>
            </form>

            <div class="text-center mt-3">
                <p class="contact-us mt-3 mb-3" v-tr>Contact us|Contactez-nous</p>
                <a class="login-link link-basic f-10" href="tel:+15146915115">514.395.9327</a><br>
                <a class="login-link link-basic f-10" href="mailto:info@eyeinmedia.com">info@eyeinmedia.com</a>
            </div>

            <div class="login-footer">Eye-In Média</div>
        </login-page>
    </app-page>
</template>

<script>
    import LoginPage from "@/layout/login-page.vue";
    import {swal} from "vue-swal";
    import EFormTooltip from "../../vue-components/components/e-form-tooltip.vue";
    import EInput from "../../vue-components/components/e-input.vue";
    import Network from "../../vue-components/helpers/Network";
    import {changeLanguage} from "../../vue-components/helpers/i18n";
    import Validation from "../../vue-components/helpers/Validation.js";

    export default {
        name: `reset-password`,
        components: {EFormTooltip, EInput, LoginPage},
        data() {
            return {
                password: ``,
                passwordConfirmation: ``
            }
        },
        computed: {
            confirmationValid() {
                return this.password === this.passwordConfirmation && Validation.testPassword(this.password, true);
            },
            passwordValid() {
                return Validation.testPassword(this.password, true);
            }
        },
        methods: {
            changeLanguage() {
                changeLanguage();
                this.$forceUpdate();
            },
            changePassword() {
                if (!this.passwordValid || !this.confirmationValid) {
                    swal({
                        icon: `info`,
                        title: this.tr(`Password not valid|Mot de passe non valide`),
                        text: this.tr(`Password doesn't match or is not valid.|Les mots de passe ne correspondent pas, ou ne sont pas valides`)
                    });
                    return;
                }

                Network.post(`/users/reset-password`, {
                    username: this.$route.params.username,
                    activation_guid: this.$route.params.guid,
                    password: this.password
                }).then(() => {
                    this.showSavedMessage(`Password changed|Mot de passe modifié`, `Your password was changed successfully, you can now login.|Votre mot de passe a été modifié avec succès, vous pouvez maintenant vous connecter.`);
                    this.$router.push(`/`);
                }).catch(() => {
                    this.showErrorMessage(`Link expired|Lien expiré`, `Your reset password link is not valid.|Votre lien de réinitialisation de mot de passe n'est pas valide.`);
                    this.$router.push(`/login`);
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>
