/* eslint-disable quotes */
import Vue from 'vue';
import VueRouter from 'vue-router';
import store from "@/store.js";
import {swal} from "vue-swal";
import Network from "../vue-components/helpers/Network.js";

import home from './views/home.vue';
import changePassword from "@/views/change-password.vue";
import resetPassword from "@/views/reset-password.vue";
import loginAs from "@/views/login-as.vue";
import testPage from "./views/test-page.vue";
import multiguard from '../vue-components/helpers/router-multiguard.js';

Vue.use(VueRouter);

function ifAuthenticated(to, from, next) {
    if (store.getters.isAuthenticated) {
        next();
    } else {
        store.dispatch(`getCurrentUser`)
            .then(() => {
                if (store.getters.isAuthenticated) {
                    next();
                } else {
                    next(`/`);
                }
            });
    }
}

function loadApplication(to, from, next) {
    store.dispatch(`loadApplication`)
        .then(() => {
            if (store.getters.isApplicationLoaded) {
                next();
            } else {
                next(`/error`);
                localStorage.removeItem(`token`);
                Network.removeToken();
                swal(this.tr(`Error|Erreur`), this.tr(`The website requesting login is not recognized. Please make sure someone is not trying to steal your credentials.|Le site ayant fait une requête d'autentification n'est pas reconnu. Vérifiez que personne n'essaie de voler vos identifiants.`), `error`);
            }
        })
}


const routes = [
    {
        path: `/`,
        name: `home`,
        component: home,
        alias: `/home`,
        beforeEnter: loadApplication
    },
    {
        path: `/change-password/:optional?`,
        name: `change-password`,
        component: changePassword,
        beforeEnter: multiguard([ifAuthenticated, loadApplication])
    },
    {
        path: `/reset-password/:username/:guid`,
        name: `reset-password`,
        component: resetPassword,
        beforeEnter: loadApplication
    },
    {
        path: `/login-as`,
        name: `login-as`,
        component: loginAs,
        beforeEnter: multiguard([ifAuthenticated, loadApplication])
    },
    {
        path: `/error`,
        name: `error`,
        component: () => import('./views/error.vue')
    },
    {
        path: `/test-page`,
        name: `test-page`,
        component: testPage,
        beforeEnter: multiguard([ifAuthenticated, loadApplication])
    }
];

const router = new VueRouter({
    mode: `history`,
    routes: routes
});

export default router;
