<template>
    <b-btn id="login-with-microsoft" v-b-tooltip variant="primary" @click="signIn" class="btn-icon" :title="'Login with Microsoft|Connexion avec Microsoft'|tr">
        <i class="fab fa-microsoft fa-fw"></i>
        <span v-if="!small" v-tr>Login with Microsoft|Connexion avec Microsoft</span>

        <modal-terms-and-policies v-if="termsAndPoliciesModalVisible" :visible.sync="termsAndPoliciesModalVisible" :terms="$store.state.terms.termsAndPolicies" @accepted="termsAccepted"/>
    </b-btn>
</template>

<script>
import Network from "../../vue-components/helpers/Network.js";
import ModalTermsAndPolicies from "@/components/modal-terms-and-policies.vue";

export default {
    name: `LoginMicrosoft`,
    components: {ModalTermsAndPolicies},
    props: {
        small: {type: Boolean, default: false}
    },
    data() {
        return {
            scriptLoaded: false,
            myMSALObj: null,
            msalConfig: null,
            loginRequest: {
                scopes: [`User.Read`]
            },
            termsAndPoliciesModalVisible: false
        }
    },
    async mounted() {
        await this.insertScript()
    },
    methods: {
        async insertScript() {
            if (document.getElementById(`microsoft-script`)) {
                this.scriptLoaded = true
                return
            }

            return new Promise(resolve => {
                const script = document.createElement(`script`)
                script.id = `microsoft-script`
                script.src = `https://alcdn.msauth.net/browser/2.26.0/js/msal-browser.js`
                script.integrity = `sha384-fitpJWrpyl840mvd9nBFLGulqR4BJzvim0fzrXQKdsVh2AQzE4rTTJ0o5o+x+dRK`
                script.crossOrigin = `anonymous`

                script.onload = () => {
                    this.myMSALObj = new msal.PublicClientApplication(this.getMSALConfig());

                    this.scriptLoaded = true
                    resolve()
                }

                document.body.appendChild(script)
            })
        },
        async signIn() {
            await this.insertScript()

            if (!this.myMSALObj) {
                this.myMSALObj = new msal.PublicClientApplication(this.getMSALConfig());
            }

            this.myMSALObj.loginPopup(this.loginRequest)
                .then(response => {
                    if (!response) {
                        console.error(`Login with Microsoft failed`)
                        return
                    }

                    Network.post(`/users/login-with-microsoft`, {
                        app: this.currentApplication.app,
                        ...response
                    })
                        .then(async resp => {
                            if (resp.data.error === `terms_required`) {
                                await this.$store.dispatch(`setTermsAndPolicies`, resp.data);
                                this.termsAndPoliciesModalVisible = true;
                            } else {
                                this.$emit(`user`, resp.data)
                            }
                        })
                        .catch(error => {
                            this.$swal({
                                icon: `error`,
                                title: this.tr(`Could not login|Connexion non valide`),
                                text: error.response.data
                            });
                        })
                })
                .catch(e => {
                    console.error(e)
                })
        },
        getMSALConfig() {
            return {
                auth: {
                    clientId: this.isLive() ? `6865c342-83cd-4723-924f-04e731ffa575` : `34ad823e-7a18-4662-9222-7c0361c8b88a`,
                    authority: `https://login.microsoftonline.com/common`,
                    redirectUri: env.uri
                },
                cache: {
                    cacheLocation: `sessionStorage`,
                    storeAuthStateInCookie: false
                },
                system: {
                    loggerOptions: {
                        loggerCallback: (level, message, containsPii) => {
                            if (containsPii) {
                                return
                            }
                            switch (level) {
                                case msal.LogLevel.Error:
                                    console.error(message)
                                    return;
                                case msal.LogLevel.Info:
                                    console.info(message)
                                    return;
                                case msal.LogLevel.Verbose:
                                    console.debug(message)
                                    return;
                                case msal.LogLevel.Warning:
                                    console.warn(message)
                                    return;
                            }
                        }
                    }
                }
            }
        },
        async termsAccepted() {
            await this.$store.dispatch(`acceptTerms`);
            this.$emit(`user`, this.$store.state.tmpUser);
        }
    }
}


</script>

<style lang="scss" scoped>

</style>
