<template>
    <div>
        <div class="form-group mb-0">
            <label for="login-password" v-tr>Your Password|Mot de passe</label>
            <i @click="showPassword = !showPassword" class="fas show-password" :class="showPassword ? 'fa-eye-slash': 'fa-eye'"/>
            <input id="login-password" @keypress="onKeyPressed" class="form-control mb-0" name="password" required v-model="password" :type="showPassword ? 'text': 'password'" placeholder=""/>
        </div>

        <b-btn @click="signIn" variant="primary" class="mt-3" block pill data-cy="login-button" v-tr>Sign In|Se connecter</b-btn>

        <div class="mt-4 mb-3">
            <b-btn @click="showForgetPassword = true" variant="secondary" block pill data-cy="forgot-password-button" v-tr>Forgot your password?|Mot de passe oublié?</b-btn>
        </div>

        <b-modal v-model="showForgetPassword" :title="'Recover password|Retrouver votre mot de passe'|tr" @ok="forgotPassword" :cancel-title="tr('Cancel|Annuler')">
            <p v-tr>Please enter your username or your email address to change your password.|Merci d'entrer votre nom d'utilisateur ou votre courriel pour retrouver votre mot de passe.</p>
            <b-form-group :label="'Username or e-mail|Nom d\'utilisateur ou e-mail'|tr">
                <b-input-group>
                    <b-input-group-text><i class="fas fa-envelope"/></b-input-group-text>
                    <b-input type="text" maxlength="50" v-model="recovery.username" autofocus data-cy="recover-password-username" :placeholder="tr('Ex: johndoe@eyeinmedia.com')"/>
                </b-input-group>
            </b-form-group>
        </b-modal>

        <b-modal v-model="smsVerificationModalVisible" data-cy="sms-modal-visible" no-close-on-backdrop :title="'Validation Code|Code de vérification'|tr" :cancel-title="tr('Cancel|Annuler')">
            <div>
                <h5 v-tr>Please enter code below|Merci d'entrer le code ci-dessous</h5>
                <b-input v-model="validationCode" placeholder="Code" data-cy="sms-code-input" size="lg" autocomplete="off" class="code-input mx-auto text-center py-4 f-13 text-uppercase" :maxlength="6"></b-input>

                <b-form-group class="mt-2">
                    <b-checkbox v-model="rememberDevice">
                        <span v-tr>Remember this device for 30 days|Se souvenir de cet appareil pour 30 jours</span>
                    </b-checkbox>
                </b-form-group>

                <div class="f-500 mt-4">
                    <div v-tr>We just sent you a verification code by SMS|Nous avons envoyé un code par SMS</div>
                </div>
            </div>

            <template #modal-footer>
                <e-button-async :disabled="!validationCode || validationCode.length < 4" @async-click="verifyPhoneNumber" data-cy="validation-code-btn" variant="primary">
                    <span v-tr>Verify|Vérifier</span>
                </e-button-async>
            </template>
        </b-modal>

        <modal-terms-and-policies :visible.sync="termsAndPoliciesModalVisible" :terms="$store.state.terms.termsAndPolicies" @accepted="signIn"/>
    </div>
</template>

<script>
import ModalTermsAndPolicies from "@/components/modal-terms-and-policies.vue";
import Network from "../../vue-components/helpers/Network.js";
import EButtonAsync from "../../vue-components/components/e-button-async.vue";

export default {
    name: `LoginPassword`,
    components: {ModalTermsAndPolicies, EButtonAsync},
    props: {
        username: {type: String, required: true}
    },
    data() {
        return {
            showForgetPassword: false,
            showPassword: false,
            password: ``,
            recovery: {
                username: ``
            },
            rememberDevice: true,
            validationCode: ``,
            smsVerificationModalVisible: false,
            termsAndPoliciesModalVisible: false
        }
    },
    methods: {
        onKeyPressed(event) {
            if (event.keyCode === 13) {
                this.signIn();
            }
        },
        async signIn() {
            // Last validation code token
            const loginCode = localStorage.getItem(`login_code`);
            this.showLoading();

            Network.post(`/users/sign-in`, {
                app: this.currentApplication.app,
                terms: this.$store.state.terms,
                username: this.username,
                password: this.password,
                login_code: loginCode || null,
                projectId: `login`
            }).then(resp => {
                if (resp.data.extra_auth_required) {
                    this.smsVerificationModalVisible = true;
                } else if (resp.data.error === `terms_required`) {
                    this.$store.state.termsAndPolicies = resp.data.termsAndPolicies;
                    this.termsAndPoliciesModalVisible = true;
                } else {
                    this.$emit(`user`, resp.data);
                }
                this.hideLoading();
            }).catch(error => {
                console.log(error.response);
                if (error.response.data && error.response.data.extra_auth_required === `sms`) {
                    this.smsVerificationModalVisible = true;
                } else if (error.response.data === `Invalid User Password`) {
                    this.$swal({
                        icon: `error`,
                        title: this.tr(`Could not login|Connexion impossible`),
                        text: this.tr(`This username doesn't exist or the password is not valid|Cet utilisateur n'existe pas ou le mot de passe n'est pas valide`)
                    });
                } else if (error.response.data === `Too many attempts`) {
                    this.$swal({
                        icon: `error`,
                        title: this.tr(`Could not login|Connexion impossible`),
                        text: this.tr(`You have reached the maximum login attempts. For security reasons your account has been locked for 10 min. Please try again later.|Vous avez dépassé le nombre maximum de d'essais. Pour des raisons de sécurité votre compte a été bloqué pour 10 min. Merci de réessayer plus tard.`)
                    });
                } else if (error.response.data && error.response.data.status === `auth_error`) {
                    let reason = error.response.data.reason;
                    switch (error.response.data.reason) {
                        case `@@errors.too_many_codes_sent`:
                            reason = this.tr(`You have reached the maximum validation attempts. Please contact technical support to continue.|Vous avez atteint le nombre maximal de tentatives de validation. Veuillez contacter le support technique pour continuer.`)
                            break;
                        case `@@errors.many_codes_sent_please_wait`:
                            reason = this.tr(`You have requested too many validation codes in a short amount of time. Please try again in a few minutes.|Vous avez demandé trop de codes de validation en peu de temps. Veuillez réessayer dans quelques minutes.`)
                            break;
                    }
                    this.$swal({
                        icon: `error`,
                        title: this.tr(`Could not login|Connexion impossible`),
                        text: reason
                    });
                } else if (error.response.data === `user_deactivated`) {
                    this.$swal({
                        icon: `error`,
                        title: this.tr(`Could not login|Connexion non valide`),
                        text: this.tr(`By security measures, your account has been deactivated. An email has been sent to the Eye-In Team. They will reach out to you as soon as possible. Thank you for your understanding|Par des mesures de sécurité, votre compte a été désactivé. Un courriel a été envoyé à l'équipe d'Eye-In. Ils vous contacteront dès que possible. Merci de votre compréhension`)
                    });
                } else {
                    this.$swal({
                        icon: `error`,
                        title: this.tr(`Could not login|Connexion non valide`),
                        text: error.response.data
                    });
                }
            });
        },
        verifyPhoneNumber(callback) {
            Network.post(`/users/login-sms-code`, {
                app: this.currentApplication.app,
                username: this.username,
                password: this.password,
                sms_code: this.validationCode,
                remember_device: this.rememberDevice,
                projectId: `login`
            }).then(resp => {
                this.smsVerificationModalVisible = false;
                this.$emit(`user`, resp.data);
            }).catch(() => {
                this.$swal({
                    icon: `error`,
                    title: this.tr(`Invalid Verification Code|Code de vérification non valide`),
                    text: this.tr(`Please verify the code (it's not case sensitive) and try again. If you keep having difficulties contact us.|Veuillez vérifier le code (il n'est pas sensible à la casse) et réessayer. Si vous continuez à avoir des difficultés, contactez-nous.`)
                });
            }).finally(() => {
                if (callback) {
                    callback();
                }
            });
        },
        forgotPassword() {
            let redirectUrl = localStorage.getItem(`redirect_url`);
            if (!redirectUrl) {
                redirectUrl = window.env.VUE_APP_URLS__EYEINTELLIGENCE
            }

            const redirectDomain = new URL(redirectUrl).host;

            Network.post(`/users/forgot-password`, {
                app: this.currentApplication.app,
                username: this.recovery.username,
                domain: redirectDomain
            }).then(() => {
                this.$swal({
                    icon: `success`,
                    title: this.tr(`Success|Succès`),
                    text: this.tr(`An email has been sent with further information.|Un courriel vous a été envoyé avec plus d'informations`)
                });
            }).catch(() => {
                this.$swal({
                    icon: `error`,
                    title: this.tr(`No account found|Aucun compte associé`),
                    text: this.tr(`Make sure this username and email are valid|Merci de vérifier que votre nom d'utilisateur et courriel sont valides`)
                });
            });
        }
    }
}


</script>

<style lang="scss" scoped>
.btn-secondary {
    margin-bottom: 20px;
    color: #56b9d7;
    letter-spacing: 1px;
}

.form-group {
    position: relative;
}

.show-password {
    position: absolute;
    right: 0;
    padding: 8px;
}

.login-btn {
    cursor: pointer;
    user-select: none;
    border-radius: 50px;
    color: #ffffff;
    text-transform: initial;
    font-weight: 400;
    letter-spacing: 2px;
    font-size: 12pt;
    background: linear-gradient(to bottom, #52b9cc 0%, #2c70b6 100%);
    box-shadow: 1px 3px 15px rgba(0, 0, 0, 0.05);
    padding: 12px 20px;
    border: 0;
    display: inline-block;
    width: 100%;
    transition: 150ms;
    position: relative;

    &.btn-icon {
        display: inline-block;
        width: auto;
        border-radius: 3px;
        margin: 0 5px;

        .fab,
        .fas {
            font-size: 20px !important;
        }
    }

    &:hover {
        transform: scale(1.05);
        background: linear-gradient(to bottom, #57ccdf 0%, #2c70b6 100%);
    }

    &:active {
        transform: scale(0.95);
        background: linear-gradient(to bottom, #51a3b6 0%, #285d9c 100%);
    }
}

.btn.btn-secondary {
    background: rgba(white, 0.1);
    border: 1px solid rgba(#57ccdf, 1);
    color: #57ccdf;

    &:hover {
        color: white;
        transform: scale(1.05);
        background: linear-gradient(to bottom, #57ccdf 0%, #2c70b6 100%);
    }

    &:active {
        color: white;
        transform: scale(0.95);
        background: linear-gradient(to bottom, #51a3b6 0%, #285d9c 100%);
    }
}

label {
    color: #56b9d7;
    border-radius: 5px;
    font-weight: 400;
    font-size: 10pt;
    line-height: 20px;
    text-align: left;
    letter-spacing: 2px;
    margin-bottom: 2px;
    margin-left: 10px;
    width: 100%;
}

#login-password {
    padding: 5px 10px;
    font-weight: 500;
    font-size: 11pt;
    border: none;
    border-bottom: 2px solid #56b9d7;
    color: white;
    width: 100%;
    background-color: transparent !important;
    border-radius: 0;
    margin-bottom: 20px;
}
</style>
